import { Link } from 'react-router-dom';
import instagram from '../../img/instagram.svg';
import youtube from '../../img/youtube.svg';
import behance from '../../img/behance.svg';
import logo from '../../img/logo.svg';
import './Footer.scss';

function Footer() {

  const navStyle = {
    color: "white",
    textDecoration: "none",
  }

  return (
    <div className='footer'>
      <div className="footer-link">
        <div><Link style={navStyle} to={"/about"}>About</Link></div>
        <div><Link style={navStyle} to={"/contact"}>Contact</Link></div>
      </div>
      <div className="footer-social-media">
        <a href="https://www.instagram.com/alprimez" rel="noreferrer noopener" target="_blank"><img src={instagram} alt="instagram" /></a>
        <a href="https://www.youtube.com/c/alprimez" rel="noreferrer noopener" target="_blank"><img src={youtube} alt="youtube" /></a>
        <a href="https://www.behance.net/alprimez" rel="noreferrer noopener" target="_blank"><img src={behance} alt="behance" /></a>
      </div>
      <div>
        <img src={logo} alt="" className="footer-logo" />
      </div>
    </div>
  )
}

export default Footer;