import "./Contact.scss";

function Contact() {
  return (
    <div className="contact">
      <h1>Contact me</h1>
      <p>Got any business, commission, and questions? <br /> Contact me!</p>
      <a href="mailto:alprimezbusiness@gmail.com">alprimezbusiness@gmail.com</a>
    </div>
  )
}

export default Contact;