import Hero from "./Hero/Hero"
import Reel from "./Showcase/Reel"

function Home() {
  return(
    <div>
      <Hero></Hero>
      <Reel></Reel>
    </div>
  )
}

export default Home;