import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logo.svg";
import menu from "../../img/menu.svg";
import "./Nav.scss";
import NavMenu from "./Nav_Menu";

function Nav() {
  let [openMenu, setOpenMenu] = useState(false);

  const navStyle = {
    height: "3rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
  const navStyle1 = {
    color: "white",
    textDecoration: "none",
  }

  function openMenuHandler() {
    setOpenMenu(!openMenu);
  }

  return (
    <>
      <nav>
        <Link to={"/"} style={navStyle}><img src={logo} alt="" className="logo icon" /></Link>
        <img src={menu} alt="" className="menu icon" onClick={openMenuHandler} />
        <div className="flex-nav">
          <ul>
            <li><Link style={navStyle1} to={"/"}>Home</Link></li>
            <li><Link style={navStyle1} to={"/About"}>About</Link></li>
            <li><Link style={navStyle1} to={"/contact"}>Contact</Link></li>
          </ul>
        </div>
      </nav>
      <NavMenu className={(openMenu) ? "open" : "close"} openMenuHandler={openMenuHandler}></NavMenu>
    </>
  );
}

export default Nav;