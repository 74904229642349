import { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import video from "../../../img/output.mp4";
import "./Hero.scss";

gsap.registerPlugin(ScrollTrigger);

function Hero() {
  const heroBox = useRef();
  const heroVid = useRef();

  useEffect(function () {

    gsap.fromTo(heroVid.current,
      { currentTime: 0 },
      {
        currentTime: 8.09, duration: 1,
        scrollTrigger: {
          trigger: heroBox.current,
          start: '0%',
          end: '150%',
          scrub: true,
          pin: true,
          pinSpacing: false,
          // markers: { startColor: 'pink', endColor: 'pink' }
        }
      });

    // gsap.timeline({
    //   scrollTrigger: {
    //     trigger: heroBox.current,
    //     start: '0%',
    //     end: '150%',
    //     pin: true,
    //     pinSpacing: false,
    //     markers: { startColor: 'blue', endColor: 'blue' }
    //   }
    // });
  });

  return (
    <div style={{ height: "150vh" }}>
      <div className="hero" ref={heroBox}>
        <video className="hero-video" ref={heroVid} src={video} type="video/mp4" muted></video>
      </div>
    </div>
  );
}

export default Hero;