import shot1 from '../../../img/shot1_1.png';
import shot2 from '../../../img/shot2.png';
import shot3 from '../../../img/shot5s.png';
import shot4 from '../../../img/shot6.png';
import "./Reel.scss";

function Reel() {
  return (
    <div className="main">
      <h1 className="showcase">
        Latest Showcase
      </h1>
      <div className="showcase-vid">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/Hk2c66Hhce8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
      <div className="showcase-pic">
        <div className="pic-cont"><img src={shot1} alt="" /></div>
        <div className="pic-cont"><img src={shot2} alt="" /></div>
        <div className="pic-cont"><img src={shot3} alt="" /></div>
        <div className="pic-cont"><img src={shot4} alt="" /></div>
      </div>
    </div>
  );
}

export default Reel;