import "./About.scss";

function About() {
  return (
    <div className="about">
      <h1>About me</h1>
      <p>
        I don't know man, <br />
        I just learn and make stuff. <br />
        I am a creative and curious person. <br />
        A designer and a programmer.
      </p>
    </div>
  )
}

export default About;