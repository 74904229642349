import { Link } from "react-router-dom";
import "./Nav_Menu.scss"

function Nav_Menu(props) {
  const navStyle = {
    color: "white",
    textDecoration: "none",
  }

  function closeMenu() {
    props.openMenuHandler();
  }

  return (
    <div className={`nav-menu ${props.className}`}>
      <ul>
        <li><Link style={navStyle} onClick={closeMenu} to={"/"}>Home</Link></li>
        <li><Link style={navStyle} onClick={closeMenu} to={"/About"}>About</Link></li>
        <li><Link style={navStyle} onClick={closeMenu} to={"/contact"}>Contact</Link></li>
      </ul>
    </div>
  );
}

export default Nav_Menu;